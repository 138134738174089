import React from "react";
import LoginLayout from "../../Components/LoginLayout";
import Button from "../../Components/Button";
import { useNavigate } from "react-router-dom";

const RecoverEmail = () => {
  const navigate = useNavigate();
  return (
    <LoginLayout
      children={
        <div className="relative flex flex-col w-full max-w-[600px] h-full border-r gap-[48px] p-[64px]">
          <img
            src="/Images/logo.png"
            className="w-[122px] h-[48px]"
            alt="logo"
          />
          <div className="flex flex-col gap-[2px]">
            <h1 className="font-[600] text-[39px]">Recover Email</h1>
            <p className="">
              Your mobile phone number is associated with the account email
              below:
            </p>
          </div>
          <div>
            <p className="text-[#365BC9] font-[700]">ex******le@gmail.com</p>
          </div>
          <p className="">
            We’ve hidden part of the email address for security reasons. Please
            contact us if you still cannot access your account.
          </p>
          <Button
            variant="solid_primary"
            className="w-fit px-[16px]"
            onClick={() => {
              navigate("/");
            }}
          >
            Back to Sign In
          </Button>
        </div>
      }
    />
  );
};

export default RecoverEmail;
