import React from "react";

const DropdownSet = ({
  value,
  options,
  label,
  dataName,
  disabled,
  required,
  onChange,
  variant = "default",
}) => {
  const label_variants = {
    default: "focused:text-[#365BC9]",
  };

  let label_classes = `flex ${label_variants[variant]}`;
  return (
    <>
      <div className="flex flex-col gap-[6px]">
        <h2 className={`${label_classes} ${disabled ? "text-[#D0D3D8]" : ""}`}>
          {label} {required && <span className="text-red-500">*</span>}
        </h2>
        <div className="relative">
          <select
            id={dataName}
            onChange={onChange}
            name={dataName}
            value={value}
            className="border border-[#E0E2E5] w-full rounded-lg hover:border-black focus:border-[#365BC9] focus:outline-[#365BC9] focus:shadow-[0px_0px_4px_0px_#6200EE80] disabled:border-[#D0D3D8] disabled:hover:border-[#D0D3D8] disabled:placeholder:text-[#D0D3D8] py-[8px] px-[16px]"
          >
            <option value={value} hidden disabled defaultValue>
              {value
                ? options[value - 1] && options[value - 1].name
                : "Select an option"}
            </option>
            {options &&
              options.map((option) => (
                <option key={option.id + " " + option.name} value={option.id}>
                  {option.name}
                </option>
              ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default DropdownSet;
