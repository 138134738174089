export const LOGIN = "/";
export const REGISTER = "/register";
export const VERIFY_EMAIL = "/verify-email";
export const DISCORD_CONNECT = "/discord-connect";
export const FORGET_PASSWORD = "/forget-password";
export const RESET_PASSWORD_EMAIL = "/reset-password-email";
export const RESET_PASSWORD = "/reset-password";
export const RESET_PASSWORD_COMPLETE = "/reset-password-complete";
export const FORGET_EMAIL = "/forget-email";
export const MOBILE_VERIFICATION = "/mobile-verification";
export const RECOVER_EMAIL = "/recover-email";
export const PLATFORM = "/platform";
