import { useState } from "react";
import Button from "../../../Components/Button";
import { LoadingOverlay } from "../../../Components/LoadingOverlay";
import TextboxSet from "../../../Components/TextboxSet";
import { useToast } from "../../../Components/Toast";
import { PRE_REGISTER_VALIDATION_API } from "../../../Constants/api";
import axios from "axios";

const RegisterPOne = ({
  registerStep,
  setRegisterStep,
  formData,
  handleChange,
}) => {
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();

  const checkUserInfo = (email, ic) => {
    setLoading(true);
    try {
      axios
        .post(PRE_REGISTER_VALIDATION_API, {
          email: email,
          ic: ic,
        })
        .then((res) => {
          setLoading(false);
          if (res.data.success === true) {
            setRegisterStep(registerStep + 1);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.data.data.email) {
            showToast(error.response.data.data.email, "error");
          }
          if (error.response.data.data.ic) {
            showToast(error.response.data.data.ic, "error");
          }
        });
    } catch (error) {
      setLoading(false);
      showToast("Failed to validate user info:", "error");
    }
  };

  return (
    <form className="flex flex-col gap-[32px]">
      {loading && <LoadingOverlay />}
      <div className="flex flex-col gap-[2px]">
        <h1 className="font-[600] text-[39px]">Register Account</h1>
        <p className="">
          Create your account and provide your personal information below.
        </p>
      </div>
      <div className="flex flex-col gap-[16px]">
        <div>
          <h2 className="font-[700] text-[16px]">Step 1/5 - Account Info</h2>
        </div>
        <TextboxSet
          type="number"
          name="ic"
          label="IC No. (Without dash '-')"
          placeholder="Your IC Number"
          value={formData.ic}
          onChange={handleChange}
          required
        />
        <TextboxSet
          type="email"
          name="email"
          label="Email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <TextboxSet
          type="number"
          name="phone_no"
          label="Mobile"
          placeholder="Your Mobile Number"
          value={formData.phone_no}
          onChange={handleChange}
          required
        />
        <TextboxSet
          type="password"
          name="password"
          label="Password"
          placeholder="Your Password"
          value={formData.password}
          onChange={handleChange}
          required
        />
        <TextboxSet
          type="password"
          name="confirmPassword"
          label="Confirm Password"
          placeholder="Your Password"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <Button
          type="submit"
          variant="solid_primary"
          className="px-[33px] "
          onClick={(e) => {
            e.preventDefault();
            if (
              formData.ic &&
              formData.email &&
              formData.phone_no &&
              formData.password &&
              formData.confirmPassword
            ) {
              if (formData.password !== formData.confirmPassword) {
                showToast("Password does not match.", "error");
              } else {
                checkUserInfo(formData.email, formData.ic);
              }
            } else {
              showToast("Please fill in all the required fields.", "error");
            }
          }}
        >
          Next
        </Button>
      </div>
    </form>
  );
};

export default RegisterPOne;
