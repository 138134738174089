import React from "react";
import LoginLayout from "../../Components/LoginLayout";
import Button from "../../Components/Button";
import { useNavigate } from "react-router-dom";

const PwResetComplete = () => {
  const navigate = useNavigate();
  return (
    <LoginLayout
      children={
        <div className="relative flex flex-col w-full max-w-[600px] h-full border-r gap-[48px] p-[64px]">
          <img
            src="/Images/logo.png"
            className="w-[122px] h-[48px]"
            alt="logo"
          />
          <div className="flex flex-col gap-[32px]">
            <div>
              <h1 className="font-[600] text-[39px] gap-[2px]">
                Reset Password
              </h1>
              <p className="">Please enter the new password below.</p>
            </div>
            <div className="flex flex-col gap-[2px]">
              <h2 className="font-[700]">Updated Successfully</h2>
              <p className="">
                Your password has been updated, you may back to sign in your
                account and start your journey with Nasa Space App Challenge
                2024!
              </p>
            </div>
            <Button
              variant="solid_primary"
              className="w-fit h-fit px-[16px]"
              onClick={() => {
                navigate("/");
              }}
            >
              Back to Sign In
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default PwResetComplete;
