import LoginLayout from "../../Components/LoginLayout";
import Button from "../../Components/Button";
import { useNavigate } from "react-router-dom";

const MobileVerification = () => {
  const navigate = useNavigate();

  return (
    <LoginLayout
      children={
        <form className="relative flex flex-col w-full max-w-[600px] h-full border-r gap-[48px] p-[64px]">
          <img
            src="/Images/logo.png"
            className="w-[122px] h-[48px]"
            alt="logo"
          />
          <div className="flex flex-col gap-[32px]">
            <div>
              <h1 className="font-[600] text-[39px] gap-[2px]">Forgot Email</h1>
              <p className="">
                Please enter your mobile number that is associated with your
                account to receive verification code.
              </p>
            </div>
            <div className="flex flex-col gap-[6px]">
              <p>
                Mobile Number<span className="text-[#FF0000]">*</span>
              </p>
              <div className="flex gap-[12px]">
                <input
                  type="text"
                  maxLength="1"
                  className="border border-[#E0E2E5] rounded-xl text-center w-[68px] h-[72px] text-[39px] "
                  placeholder="0"
                />
                <input
                  type="text"
                  maxLength="1"
                  className="border border-[#E0E2E5] rounded-xl text-center w-[68px] h-[72px] text-[39px] "
                  placeholder="0"
                />
                <input
                  type="text"
                  maxLength="1"
                  className="border border-[#E0E2E5] rounded-xl text-center w-[68px] h-[72px] text-[39px] "
                  placeholder="0"
                />
                <input
                  type="text"
                  maxLength="1"
                  className="border border-[#E0E2E5] rounded-xl text-center w-[68px] h-[72px] text-[39px] "
                  placeholder="0"
                />
                <input
                  type="text"
                  maxLength="1"
                  className="border border-[#E0E2E5] rounded-xl text-center w-[68px] h-[72px] text-[39px] "
                  placeholder="0"
                />
                <input
                  type="text"
                  maxLength="1"
                  className="border border-[#E0E2E5] rounded-xl text-center w-[68px] h-[72px] text-[39px] "
                  placeholder="0"
                />
              </div>
            </div>
            <div className="flex gap-[16px]">
              <Button
                variant="outline_neutral"
                onClick={() => {
                  navigate("/forget-email");
                }}
              >
                Back
              </Button>
              <Button
                variant="solid_primary"
                className="w-fit h-fit px-[24px]"
                onClick={() => {
                  navigate("/recover-email");
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      }
    />
  );
};

export default MobileVerification;
