import React, { useEffect } from "react";
import { useState } from "react";
import Button from "../../../Components/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  RESEND_VERIFICATION_EMAIL_API,
  VERIFY_EMAIL_API,
} from "../../../Constants/api.js";
import { LoadingOverlay } from "../../../Components/LoadingOverlay.jsx";
import CountdownTimer from "../../../Components/CountdownTimer.jsx";
import { useToast } from "../../../Components/Toast.jsx";

const RegisterPFive = ({ registerStep, setRegisterStep, registeredEmail }) => {
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const navigate = useNavigate();

  const handleResend = (e, childSetIsActive) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(RESEND_VERIFICATION_EMAIL_API, { email: registeredEmail })
      .then((res) => {
        setLoading(false);
        childSetIsActive(true);
      })
      .catch((err) => {
        setLoading(false);
        showToast("Failed to resend verification code", "error");
        console.log(err);
      });
  };

  const handleVerifyEmail = (e) => {
    const concatenatedValues = concatenateInputValues();
    setLoading(true);
    axios
      .post(VERIFY_EMAIL_API, {
        email: registeredEmail,
        token: concatenatedValues,
      })
      .then((res) => {
        setLoading(false);
        navigate("/verify-email");
        showToast("Email verified successfully", "success");
      })
      .catch((err) => {
        setLoading(false);
        showToast("Invalid verification code", "error");
      });
  };

  const handleChange = (index, value) => {
    const updatedValues = [...inputValues];
    updatedValues[index] = value;
    setInputValues(updatedValues);
  };

  function handleKeyDown(e, index) {
    if (
      e.target.value.length >= 1 &&
      index + 1 < inputValues.length &&
      e.key !== "Backspace"
    ) {
      const nextSibling = e.target.nextElementSibling;
      if (nextSibling) {
        nextSibling.focus();
      }
    }

    if (e.key === "Backspace") {
      setTimeout(() => {
        if (index > 0 && e.target.value.length === 0) {
          const prevSibling = e.target.previousElementSibling;
          if (prevSibling) {
            prevSibling.focus();
          }
        }
      }, 0);
    }
  }

  const concatenateInputValues = () => {
    return inputValues.join("");
  };

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  return (
    <form className="flex flex-col gap-[32px]">
      {loading && <LoadingOverlay />}
      <div className="flex flex-col gap-[2px]">
        <h1 className="font-[600] text-[39px]">Register Account</h1>
        <p className="">
          Create your account and provide your personal information below.
        </p>
      </div>
      <div className="flex flex-col gap-[32px]">
        <div>
          <h2 className="font-[700] text-[16px]">
            Step 5/5 - Email Verification
          </h2>
          <p className="">
            We've sent a verification code to your email,
            <span className="text-[#365BC9]"> {registeredEmail}</span>
          </p>
        </div>
        <div className="flex flex-col gap-[12px]">
          <div className="flex gap-[12px]">
            {inputValues.map((value, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                className="border border-[#E0E2E5] rounded-xl text-center w-[68px] h-[72px] text-[39px]"
                placeholder="0"
                value={value}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            ))}
          </div>
          <CountdownTimer onClick={handleResend} />
        </div>
        <div className="flex gap-[16px]">
          {/* <Button
            variant="outline_neutral"
            className="px-[33px] "
            onClick={() => {
              setRegisterStep(registerStep - 1);
            }}
          >
            Back
          </Button> */}
          <Button
            variant="solid_primary"
            className="px-[33px] "
            onClick={(e) => {
              e.preventDefault();
              handleVerifyEmail(e);
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </form>
  );
};

export default RegisterPFive;
