import { useState } from "react";
import Button from "../../Components/Button";
import LoginLayout from "../../Components/LoginLayout";
import { useNavigate } from "react-router-dom";
import TextboxSet from "../../Components/TextboxSet";
import axios from "axios";
import { FORGOT_PASSWORD_API } from "../../Constants/api";
import { useToast } from "../../Components/Toast";
import { LoadingOverlay } from "../../Components/LoadingOverlay";
import { REGISTER } from "../../Router/path";

const ForgetPw = () => {
  const [email, setEmail] = useState("");
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    setLoading(true);
    axios
      .post(FORGOT_PASSWORD_API, { email: email })
      .then((res) => {
        if (res.data.success === true) {
          setLoading(false);
          navigate("/reset-password-email", { state: email });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        showToast(err.response.data.message, "error");
      });
  };
  return (
    <LoginLayout
      children={
        <div className="relative flex flex-col w-full max-w-[600px] h-full border-r gap-[48px] p-[64px]">
          {loading && <LoadingOverlay />}
          <img
            src="/Images/logo.png"
            className="w-[122px] h-[48px]"
            alt="logo"
          />
          <div className="flex flex-col gap-[32px]">
            <div>
              <h1 className="font-[600] text-[39px] gap-[2px]">
                Reset Password
              </h1>
              <p className="">
                Please enter your email address, You will receive a link to
                create a new password via email.
              </p>
            </div>
            <TextboxSet
              label="Email"
              name="email"
              placeholder="Your Email"
              value={email}
              onChange={handleChange}
              required
            />
            <div className="flex gap-[16px]">
              <Button
                variant="outline_neutral"
                className="w-[96px] h-[42px]"
                onClick={() => {
                  navigate("/");
                }}
              >
                Back
              </Button>
              <Button
                variant="solid_primary"
                className="w-[96px] h-[42px]"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
            {/* <div>
              <Button
                variant="staticlinks"
                className=""
                onClick={() => {
                  navigate("/forget-email");
                }}
              >
                Forget Email?
              </Button>
            </div> */}
            <p className="absolute w-full bottom-[32px] font-[600]">
              Don't have an account?{" "}
              <Button
                variant="links"
                onClick={() => {
                  navigate(REGISTER);
                }}
              >
                Sign Up here
              </Button>
            </p>
          </div>
        </div>
      }
    />
  );
};

export default ForgetPw;
