import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import Button from "../Button";
import { DISCONNECT_DISCORD_API, LOGOUT_API } from "../../Constants/api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useToast } from "../Toast";
import { LoadingOverlay } from "../LoadingOverlay";

const DiscordCard = ({ discord_id, discord_tag }) => {
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const navigate = useNavigate();

  const handleDiscordDisconnect = (e) => {
    setLoading(true);
    e.preventDefault();
    axios
      .post(
        DISCONNECT_DISCORD_API,
        {
          discord_id: discord_id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("bearer_token")}`,
          },
        }
      )
      .then((res) => {
        axios
          .post(LOGOUT_API)
          .then((res) => {
            if (res.data.success === true) {
              setLoading(false);
              sessionStorage.removeItem("discord_required");
              sessionStorage.removeItem("bearer_token");
              navigate("/");
              showToast(
                "Discord disconnected successfully, please login again.",
                "success"
              );
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            setLoading(false);
            showToast("Logout failed", "error");
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <div className="flex flex-col border border-[#F7F8F9] w-full p-[24px] bg-white rounded-2xl gap-[16px] shadow-[0_8px_16px_-4px_rgba(23,23,24,0.04)]">
        {loading && <LoadingOverlay />}
        <div className="flex w-full justify-between items-center">
          <img
            src="/Images/images.png"
            className="max-w-[40px] max-h-[40px] rounded-lg"
            alt="discord-avatar"
          />
          {discord_id && discord_tag ? (
            <p className="flex w-fit h-fit bg-[#F4FCF7] text-[#22C55E] rounded-xl px-[8px] py-[4px] gap-[8px] items-center">
              <Icon icon="oui:dot" />
              Connected
            </p>
          ) : (
            <p className="flex w-fit h-fit bg-[#F7F8F9]  text-[#636C7E] rounded-xl px-[8px] py-[4px] gap-[8px] items-center">
              <Icon icon="oui:dot" />
              Not Connected
            </p>
          )}
        </div>
        <div className="w-full h-full">
          <h2 className="font-[600]">{discord_tag ?? "Connect Discord"}</h2>
          <p className=" text-[#636C7E]">
            {discord_id ??
              "Connect your discord account to gain access to our events and more!"}
          </p>
        </div>
        {discord_id && discord_tag ? (
          <Button
            variant="solid_error"
            onClick={(e) => handleDiscordDisconnect(e)}
          >
            Disconnect
          </Button>
        ) : (
          <Button variant="discord">Connect Discord</Button>
        )}
      </div>
    </>
  );
};

export default DiscordCard;
