import { useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import Button from "../Components/Button";
import LoginLayout from "../Components/LoginLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { LOGOUT_API } from "../Constants/api";
import { LoadingOverlay } from "../Components/LoadingOverlay";
import { useToast } from "../Components/Toast";

const DiscordConnect = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { showToast } = useToast();
  const connectionStatus = searchParams.get("discord_success");

  useEffect(() => {
    if (connectionStatus === "true") {
      sessionStorage.removeItem("discord_required");
      navigate("/platform");
    } else if (connectionStatus === "false") {
      setLoading(true);
      axios
        .post(LOGOUT_API)
        .then((res) => {
          if (res.data.success === true) {
            setLoading(false);
            sessionStorage.removeItem("discord_required");
            sessionStorage.removeItem("bearer_token");
            navigate("/");
            showToast("Discord connection failed. Please try again.", "error");
          }
        })
        .catch((err) => {
          setLoading(false);
          showToast("Logout failed", "error");
        });
    }
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(LOGOUT_API)
      .then((res) => {
        if (res.data.success === true) {
          setLoading(false);
          sessionStorage.removeItem("discord_required");
          sessionStorage.removeItem("bearer_token");
          navigate("/");
          showToast("Logout successful", "success");
        }
      })
      .catch((err) => {
        setLoading(false);
        showToast("Logout failed", "error");
      });
  };

  return (
    <LoginLayout
      children={
        <div className="relative flex flex-col w-full max-w-[600px] h-full border-r gap-[48px] p-[64px]">
          {loading && <LoadingOverlay />}
          <img
            src="/Images/logo.png"
            className="w-[122px] h-[48px]"
            alt="logo"
          />
          <div className="flex flex-col gap-[32px]">
            <div className="flex flex-col gap-[12px]">
              <Icon
                icon="ic:baseline-discord"
                className="w-[32px] h-[32px] rounded-full text-white bg-[#5865F2] p-[8px]"
              />
              <div className="flex flex-col gap-[2px]">
                <h1 className="text-[25px] font-[600]">Connect Discord</h1>
                <p className="">
                  Participants are required to connect their discord account for
                  unlocking the features on the site. Some of our events will be
                  also running on discord.
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-[16px]">
              <Button
                variant="solid_primary"
                className="w-full p-[8px]"
                onClick={() => {
                  const emailJson = JSON.stringify({
                    email: localStorage.getItem("email"),
                  });
                  const encodedEmail = encodeURIComponent(emailJson);
                  const oauth2Url = process.env.REACT_APP_OAUTH2_URL;
                  window.location.href = `${oauth2Url}&state=${encodedEmail}`;
                  localStorage.removeItem("email");
                }}
              >
                Connect Account
              </Button>
              <Button
                variant="solid_error"
                className="w-full p-[8px]"
                onClick={(e) => {
                  handleLogout(e);
                }}
              >
                Logout
              </Button>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default DiscordConnect;
