import { useState } from "react";
import Button from "../Components/Button";
import { useNavigate } from "react-router-dom";
import LoginLayout from "../Components/LoginLayout";
import TextboxSet from "../Components/TextboxSet";
import { LOGIN_API } from "../Constants/api.js";
import axios from "axios";
import { LoadingOverlay } from "../Components/LoadingOverlay.jsx";
import { useToast } from "../Components/Toast.jsx";

const Login = () => {
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();

  const navigate = useNavigate();

  const handleChange = (e) => {
    setLoginForm({
      ...loginForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = (e) => {
    if (loginForm.email && loginForm.password) {
      setLoading(true);
      axios({
        method: "post",
        url: LOGIN_API,
        data: {
          email: loginForm.email,
          password: loginForm.password,
        },
      })
        .then((res) => {
          if (res.data.data.resend === false) {
            sessionStorage.setItem("bearer_token", res.data.data.bearer_token);
            if (res.data.data.showDiscordPage) {
              sessionStorage.setItem(
                "discord_required",
                res.data.data.showDiscordPage
              );
              localStorage.setItem("email", loginForm.email);
              setLoading(false);
              navigate("/discord-connect");
            } else {
              navigate("/platform");
            }
            showToast("Login successful", "success");
          } else {
            setLoading(false);
            showToast("Please verify your email first", "error");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          showToast("Invalid email or password", "error");
        });
    } else {
      showToast("Please fill in all fields", "error");
    }
  };

  return (
    <LoginLayout
      children={
        <div className="flex flex-col w-full max-w-[600px] h-full justify-center border-r gap-[48px] p-[64px]">
          {loading && <LoadingOverlay />}
          <img
            src="/Images/logo.png"
            className="w-[122px] h-[48px]"
            alt="logo"
          />
          <div className="flex flex-col gap-[32px]">
            <div className="flex flex-col gap-[2px]">
              <h1 className="font-[600] text-[39px]">
                Join NASA Space Apps Sarawak {`${new Date().getFullYear()}`}
              </h1>
              <p>Sign in your account to continue your journey.</p>
            </div>
            <form className="flex flex-col gap-[24px]">
              <TextboxSet
                type="email"
                name="email"
                label="Email"
                placeholder="Your Email"
                value={loginForm.email}
                onChange={handleChange}
                required
              />
              <TextboxSet
                type="password"
                name="password"
                label="Password"
                placeholder="Your Password"
                value={loginForm.password}
                onChange={handleChange}
                required
              />
            </form>
            <div className="flex flex-col w-full gap-[24px] items-start">
              <Button
                variant="outline_neutral"
                className="w-full p-[8px]"
                onClick={handleLogin}
              >
                Sign In
              </Button>
              <div className="flex flex-col items-start gap-[8px]">
                <Button
                  variant="staticlinks"
                  onClick={() => {
                    navigate("/forget-password");
                  }}
                >
                  Forget Password?
                </Button>
                {/* TODO: Uncomment when ready */}
                {/* <Button
                  variant="staticlinks"
                  onClick={() => {
                    navigate("/forget-email");
                  }}
                >
                  Forget Email?
                </Button> */}
              </div>
              <div className="flex items-center w-full">
                <hr className="flex-grow border-t border-gray-300" />
                <span className="px-2 text-gray-600">or</span>
                <hr className="flex-grow border-t border-gray-300" />
              </div>
              <div className="w-full">
                <Button
                  variant="solid_primary"
                  className="w-full p-[8px]"
                  onClick={() => navigate("register")}
                >
                  Register
                </Button>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default Login;
