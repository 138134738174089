import React from "react";
import Button from "../../../Components/Button";
import TextboxSet from "../../../Components/TextboxSet.jsx";
import { useToast } from "../../../Components/Toast.jsx";

const RegisterPFour = ({
  registerStep,
  setRegisterStep,
  formData,
  handleChange,
  handleRegister,
}) => {
  const { showToast } = useToast();

  return (
    <form className="flex flex-col gap-[32px]">
      <div className="flex flex-col gap-[2px]">
        <h1 className="font-[600] text-[39px]">Register Account</h1>
        <p className="">
          Create your account and provide your personal information below.
        </p>
      </div>
      <div className="flex flex-col gap-[32px]">
        <div>
          <h2 className="font-[700] text-[16px]">
            Step 4/5 - Email Verification
          </h2>
        </div>
        <div className="flex flex-col w-full gap-[16px]">
          <TextboxSet
            name="guardian_name"
            label="Parent/Guardian Name"
            placeholder="Name"
            value={formData.guardian_name}
            onChange={handleChange}
          />
          <TextboxSet
            type="number"
            name="guardian_phone_no"
            label="Parent/Guardian Mobile Number"
            placeholder="Mobile Number"
            value={formData.guardian_phone_no}
            onChange={handleChange}
          />
          <TextboxSet
            name="guardian_email"
            type="email"
            label="Parent/Guardian Email"
            placeholder="Email Address"
            value={formData.guardian_email}
            onChange={handleChange}
          />
          <div className="flex gap-[8px] justify-start">
            <input
              type="checkbox"
              name="guardian_permission"
              value={true}
              checked={formData.guardian_permission}
              onChange={handleChange}
            />
            Do you have permission from your parent or guardian to participate
            in this hackathon?
          </div>
          <div className="flex gap-[8px] justify-start">
            <input
              type="checkbox"
              name="tnc"
              value={true}
              checked={formData.tnc}
              onChange={handleChange}
            />
            <p>
              I have read and understand the Terms and Condition.
              <span className="text-red-600">*</span>
            </p>
          </div>
          <div className="flex gap-[8px] justify-start">
            <input
              type="checkbox"
              name="public_display_agreement"
              value={true}
              checked={formData.public_display_agreement}
              onChange={handleChange}
            />
            <p>
              I understand and agree that the text, photographs, and/or videos
              containing the words, image and/or voice of all the participants
              above may be used in the production of instructional and/or
              promotional materials produced by or on behalf of Realfun Centre
              and that such materials may be distributed or broadcast to the
              public and displayed publicly.{" "}
              <span className="text-red-600">*</span>
            </p>
          </div>
        </div>
      </div>
      <div className="flex gap-[16px]">
        <Button
          variant="outline_neutral"
          className="px-[33px] "
          onClick={() => {
            setRegisterStep(registerStep - 1);
          }}
        >
          Back
        </Button>
        <Button
          type="submit"
          variant="solid_primary"
          className="px-[33px] "
          onClick={(e) => {
            e.preventDefault();
            if (formData.tnc && formData.public_display_agreement) {
              handleRegister();
            } else {
              showToast("Please fill in all the required fields.", "error");
            }
          }}
        >
          Next
        </Button>
      </div>
    </form>
  );
};

export default RegisterPFour;
