import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../Components/Button";
import LoginLayout from "../../Components/LoginLayout";
import axios from "axios";
import { FORGOT_PASSWORD_API } from "../../Constants/api";
import { useToast } from "../../Components/Toast";
import { useState } from "react";
import { LoadingOverlay } from "../../Components/LoadingOverlay";

const PwResetEmail = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { showToast } = useToast();
  const state = location.state;

  const handleResendEmail = () => {
    setLoading(true);
    axios
      .post(FORGOT_PASSWORD_API, { email: state })
      .then((res) => {
        if (res.data.success === true) {
          setLoading(false);
          showToast("Email sent successfully", "success");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        showToast(err.response.data.message, "error");
      });
  };

  return (
    <LoginLayout
      children={
        <div className="relative flex flex-col w-full max-w-[600px] h-full border-r gap-[48px] p-[64px]">
          {loading && <LoadingOverlay />}
          <img
            src="/Images/logo.png"
            className="w-[122px] h-[48px]"
            alt="logo"
          />
          <div className="flex flex-col gap-[32px]">
            <div>
              <h1 className="font-[600] text-[39px] gap-[2px]">
                Reset Password
              </h1>
              <p className="">
                Please enter your email address, You will receive a link to
                create a new password via email.
              </p>
            </div>
            <div className="flex flex-col gap-[2px]">
              <h2 className="font-[700]">Check Your Email</h2>
              <p className="">
                You will receive a link in the email you submitted to reset your
                account password.
              </p>
            </div>
            <h2 className="text-[#365BC9] font-[700]">{state}</h2>
            <p className="">
              If you don’t see the email, check other places as junk, spam or
              other folders.
            </p>
            <div className="flex gap-[16px]">
              <Button
                variant="solid_primary"
                className="w-fit h-fit px-[16px]"
                onClick={handleResendEmail}
              >
                Resend Email
              </Button>
              <Button
                variant="texto_primary"
                className="px-[16px]"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/");
                }}
              >
                Return to Login page
              </Button>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default PwResetEmail;
