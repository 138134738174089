const API_BASE_PREFIX = "https://nasaspace-app-uat.meo-studio.com/api/v1/";
const AUTH_API_PREFIX = `${API_BASE_PREFIX}auth/`;
const PUBLIC_API_PREFIX = `${API_BASE_PREFIX}public/`;
const OPTIONS_API_PREFIX = `${PUBLIC_API_PREFIX}options/`;
const AUTHENTICATED_API_PREFIX = `${API_BASE_PREFIX}authenticated/`;

// #region user authentication + verification
export const LOGIN_API = `${AUTH_API_PREFIX}login`;
export const REGISTER_API = `${AUTH_API_PREFIX}register`;
export const LOGOUT_API = `${AUTH_API_PREFIX}logout`;
export const VERIFY_USER_API = `${AUTH_API_PREFIX}verify-user`;
export const VERIFY_EMAIL_API = `${AUTH_API_PREFIX}verify-email`;
export const BATCH_SEND_EMAIL_API = `${AUTH_API_PREFIX}batch-email`;
export const UPDATE_DISCORD_ID = `${AUTH_API_PREFIX}update-discord-id`;
export const GET_DISCORD_INVITATION = `${AUTH_API_PREFIX}discord-invitation`;
export const RESEND_VERIFICATION_EMAIL_API = `${AUTH_API_PREFIX}resend-verification-email`;
export const FORGOT_PASSWORD_API = `${AUTH_API_PREFIX}forgot-password-email`;
export const PRE_REGISTER_VALIDATION_API = `${AUTH_API_PREFIX}pre-register-validation`;
export const RESET_PASSWORD_API = `${AUTH_API_PREFIX}forgot-password`;

// #region options
export const GENDER_OPTIONS_API = `${OPTIONS_API_PREFIX}gender`;
export const RACE_OPTIONS_API = `${OPTIONS_API_PREFIX}race`;
export const DISTRICT_OPTIONS_API = `${OPTIONS_API_PREFIX}district`;
export const SCHOOL_OPTIONS_API = `${OPTIONS_API_PREFIX}school`;
export const CATEGORY_OPTIONS_API = `${OPTIONS_API_PREFIX}category`;
export const OCCUPATION_OPTIONS_API = `${OPTIONS_API_PREFIX}occupation`;
export const DISCOVER_METHOD_OPTIONS_API = `${OPTIONS_API_PREFIX}discover-method`;

// #region home
export const GET_USER_INFO_API = `${AUTHENTICATED_API_PREFIX}home/user-info`;
export const GET_EVENT_LIST_API = `${AUTHENTICATED_API_PREFIX}home/event-list`;
export const DISCONNECT_DISCORD_API = `${AUTHENTICATED_API_PREFIX}home/disconnect-discord`;

// #region profile
export const UPLOAD_TEMP_IMAGE_API = `${AUTHENTICATED_API_PREFIX}profile/upload-temp-image`;
export const UPLOAD_IMAGE_API = `${AUTHENTICATED_API_PREFIX}profile/upload-image`;
export const GET_PERSONAL_INFO_API = `${AUTHENTICATED_API_PREFIX}profile/personal-info`;
export const UPDATE_PERSONAL_INFO_API = `${AUTHENTICATED_API_PREFIX}profile/update-personal-info`;
export const UPDATE_TEAM_INFO_API = `${AUTHENTICATED_API_PREFIX}profile/update-team-info`;

// #region points
export const GET_USER_POINTS_API = `${AUTHENTICATED_API_PREFIX}points/user-points`;
export const GET_USER_PROGRESS_API = `${AUTHENTICATED_API_PREFIX}points/user-progress`;
export const GET_USER_POINTS_HISTORY_API = `${AUTHENTICATED_API_PREFIX}points/points-history`;
