import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import LoadingInComponent from "../LoadingInComponent";

const TotalPointCard = ({ totalPoint, isLoading }) => {
  return (
    <div className="flex border border-[#F7F8F9] w-full p-[24px] bg-white rounded-2xl gap-[16px] shadow-[0_8px_16px_-4px_rgba(23,23,24,0.04)]">
      <Icon
        icon="fa-solid:dot-circle"
        className="w-[40px] h-[40px] bg-[#365BC91F] text-[#365BC9] rounded-xl p-[10px]"
      />
      <div className="flex flex-col w-full">
        <p className="w-full ">Total Points Earned</p>
        <LoadingInComponent isLoading={isLoading}>
          <h1 className="w-full font-[600] text-[39px]">{totalPoint}</h1>
        </LoadingInComponent>
      </div>
    </div>
  );
};

export default TotalPointCard;
