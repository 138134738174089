import React from "react";
import { useEffect } from "react";
import LoginLayout from "../../Components/LoginLayout";
import Button from "../../Components/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { VERIFY_EMAIL_API } from "../../Constants/api";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const token = searchParams.get("token");

  useEffect(() => {
    if (email && token) {
      axios
        .post(VERIFY_EMAIL_API, {
          email: email,
          token: token,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [email, token]);

  return (
    <LoginLayout
      children={
        <div className="relative flex flex-col w-full max-w-[600px] h-full border-r gap-[48px] p-[64px]">
          <img
            src="/Images/logo.png"
            className="w-[122px] h-[48px]"
            alt="logo"
          />
          <div className="flex flex-col gap-[32px]">
            <div className="flex flex-col gap-[2px]">
              <h1 className="font-[600] text-[39px]">Register Account</h1>
              <p className="">
                Create your account and provide your personal information below.
              </p>
            </div>
            <div className="flex flex-col gap-[2px]">
              <h2 className="font-[700]">Verified Successfully!</h2>
              <p className="">
                Your account has been registered. Return to the sign-in page to
                start your journey with the NASA Space App Sarawak
                {`${new Date().getFullYear()}`}!
              </p>
            </div>
            <div>
              <Button
                variant="solid_primary"
                className="px-[16px]"
                onClick={() => {
                  navigate("/");
                }}
              >
                Back to Sign In
              </Button>
            </div>
            <p className="absolute w-full bottom-[32px] font-[600]">
              Don't have an account?{" "}
              <Button
                variant="links"
                onClick={() => {
                  navigate("/register");
                }}
              >
                Sign Up here
              </Button>
            </p>
          </div>
        </div>
      }
    />
  );
};

export default VerifyEmail;
