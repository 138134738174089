import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import Button from "../Button";

const BenefitsCard = ({ pastParticipant }) => {
  return (
    <div className="flex flex-col border border-[#F7F8F9] w-full p-[24px] bg-white rounded-2xl gap-[16px] shadow-[0_8px_16px_-4px_rgba(23,23,24,0.04)]">
      <div className="flex w-full justify-between items-center">
        <img
          src="/Images/benefit-icon.png"
          className="max-w-[40px] max-h-[40px] rounded-lg"
          alt="benefit-icon"
        />
        {pastParticipant && pastParticipant === 1 ? (
          <p className="flex w-fit h-fit bg-[#F4FCF7]  text-[#22C55E] rounded-xl px-[8px] py-[4px] gap-[8px] items-center">
            <Icon icon="oui:dot" />
            Eligible
          </p>
        ) : (
          <p className="flex w-fit h-fit bg-[#F7F8F9]  text-[#636C7E] rounded-xl px-[8px] py-[4px] gap-[8px] items-center">
            <Icon icon="oui:dot" />
            Not Eligible
          </p>
        )}
      </div>
      <div className="w-full h-full">
        <h1 className="font-[600]">Past Participant Benefits</h1>
        {pastParticipant && pastParticipant === 1 ? (
          <p className=" text-[#636C7E]">
            You can get some exclusive rewards if you are past participant!
          </p>
        ) : (
          <p className=" text-[#636C7E]">
            You are not eligible to receive any benefits. As you are not a past
            participant.
          </p>
        )}
      </div>
      {pastParticipant === 1 ? (
        <Button variant="solid_primary">Read More</Button>
      ) : (
        <div />
      )}
    </div>
  );
};

export default BenefitsCard;
