import React from "react";
import { useState } from "react";
import Button from "./Button";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { LOGOUT_API } from "../Constants/api";
import { LoadingOverlay } from "./LoadingOverlay";
import { useToast } from "./Toast";

const Header = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { showToast } = useToast();

  const handleLogout = () => {
    setLoading(true);
    axios
      .post(LOGOUT_API)
      .then((res) => {
        if (res.data.success === true) {
          setLoading(false);
          sessionStorage.removeItem("discord_required");
          sessionStorage.removeItem("bearer_token");
          navigate("/");
          showToast("Logout successful", "success");
        }
      })
      .catch((err) => {
        setLoading(false);
        showToast("Logout failed", "error");
      });
  };

  return (
    <div className="flex w-full px-[32px] pt-[32px] justify-between">
      {loading && <LoadingOverlay />}
      <img
        src="/Images/logo-invert.png"
        className="w-[81px] h-[32px]"
        alt="logo"
      />
      <Button
        variant="outline_white"
        className="flex gap-[6px] items-center justify-center px-[16px]"
        onClick={() => {
          handleLogout();
        }}
      >
        Sign Out <Icon icon="heroicons-outline:logout" />
      </Button>
    </div>
  );
};

export default Header;
