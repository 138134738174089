import React, { useState } from "react";
import { UPLOAD_TEMP_IMAGE_API } from "../Constants/api";
import { useToast } from "./Toast";
import Dropzone from "react-dropzone";
import axios from "axios";
import { Icon } from "@iconify/react";

const UploadImage = ({ handleChange }) => {
  const { showToast } = useToast();

  const handleUploadImage = (acceptedFiles) => {
    const formData = new FormData();

    formData.append("file", acceptedFiles[0]);

    axios
      .post(UPLOAD_TEMP_IMAGE_API, formData, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("bearer_token")}`,
        },
      })
      .then((res) => {
        handleChange(res.data.data.url);
        showToast("Image uploaded successfully", "success");
      })
      .catch((err) => {
        console.log(err);
        showToast("Failed to upload image", "error");
      });
  };

  return (
    <Dropzone
      onDrop={handleUploadImage}
      accept={{ "image/*": [".jpeg", ".png", ".gif", ".bmp", ".svg", ".jpg"] }}
      className="w-full h-full border-black"
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
      }) => {
        const additionalClass = isDragAccept
          ? "border-green-500"
          : isDragReject
          ? "border-red-500"
          : "border-black";

        return (
          <section className="flex w-full">
            <div
              {...getRootProps()}
              className={`flex flex-col border w-full h-full py-[24px] justify-center items-center cursor-pointer border-dashed rounded-lg ${additionalClass}`}
            >
              <input {...getInputProps()} />
              <Icon
                icon="simple-line-icons:cloud-upload"
                className="w-[40px] h-[40px] bg-[#365BC91F] rounded-xl p-[8px] text-[#365BC9]"
              />
              <p className="">Click to upload or drag and drop</p>
              <p className="">png, jpg, jpeg</p>
            </div>
          </section>
        );
      }}
    </Dropzone>
  );
};

export default UploadImage;
