import { useState } from "react";
import Button from "../../Components/Button";
import LoginLayout from "../../Components/LoginLayout";
import { useNavigate } from "react-router-dom";
import TextboxSet from "../../Components/TextboxSet";

const ForgetEmail = () => {
  const [phoneNumber, setPhoneNumber] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  return (
    <LoginLayout
      children={
        <form className="relative flex flex-col w-full max-w-[600px] h-full border-r gap-[48px] p-[64px]">
          <img
            src="/Images/logo.png"
            className="w-[122px] h-[48px]"
            alt="logo"
          />
          <div className="flex flex-col gap-[32px]">
            <div>
              <h1 className="font-[600] text-[39px] gap-[2px]">Forgot Email</h1>
              <p className="">
                Please enter your mobile number that is associated with your
                account to receive verification code.
              </p>
            </div>
            <TextboxSet
              name="phoneNum"
              label="Mobile Number"
              className="w-full focus:outline-none"
              placeholder="Your Password"
              value={phoneNumber}
              onChange={handleChange}
              required
            />
            <div className="flex gap-[16px]">
              <Button
                variant="outline_neutral"
                onClick={() => {
                  navigate("/");
                }}
              >
                Back
              </Button>
              <Button
                variant="solid_primary"
                className="w-fit h-fit px-[24px]"
                onClick={() => {
                  if (phoneNumber) {
                    navigate("/mobile-verification");
                  } else {
                    alert("Please fill in all the required fields.");
                  }
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      }
    />
  );
};

export default ForgetEmail;
