import React from "react";
import Button from "../../../Components/Button";
import PersonalInfoForm from "../../../Components/Forms/PersonalInfoForm";
import { useToast } from "../../../Components/Toast.jsx";

const RegisterPTwo = ({
  registerStep,
  setRegisterStep,
  formData,
  handleChange,
  raceOptions,
  genderOptions,
  districtOptions,
}) => {
  const { showToast } = useToast();

  return (
    <form className="flex flex-col gap-[32px]">
      <div className="flex flex-col gap-[2px]">
        <h1 className="font-[600] text-[39px]">Register Account</h1>
        <p className="">
          Create your account and provide your personal information below.
        </p>
      </div>
      <div className="flex flex-col gap-[16px]">
        <div>
          <h2 className="font-[700] text-[16px]">Step 2/5 - Personal Info</h2>
        </div>
        <PersonalInfoForm
          personalInfo={formData}
          handleChange={handleChange}
          districtOptions={districtOptions}
          genderOptions={genderOptions}
          raceOptions={raceOptions}
        />
      </div>
      <div className="flex gap-[16px]">
        <Button
          variant="outline_neutral"
          className="px-[33px] "
          onClick={() => {
            setRegisterStep(registerStep - 1);
          }}
        >
          Back
        </Button>
        <Button
          variant="solid_primary"
          className="px-[33px] "
          onClick={(e) => {
            e.preventDefault();
            if (
              formData.name &&
              formData.race_id &&
              formData.gender_id &&
              formData.district_id
            ) {
              setRegisterStep(registerStep + 1);
            } else {
              showToast("Please fill in all the required fields.", "error");
            }
          }}
        >
          Next
        </Button>
      </div>
    </form>
  );
};

export default RegisterPTwo;
