import { useEffect, useState } from "react";
import TotalPointCard from "../../Components/PointHistoryCards/TotalPointCard";
import { Icon } from "@iconify/react/dist/iconify.js";
import ReactPaginate from "react-paginate";
import axios from "axios";
import {
  GET_USER_POINTS_API,
  GET_USER_POINTS_HISTORY_API,
  GET_USER_PROGRESS_API,
} from "../../Constants/api";
import LoadingInComponent from "../../Components/LoadingInComponent";
import { motion as m } from "framer-motion";

const PointHistory = () => {
  const [pagination, setPagination] = useState({});
  const [totalPoint, setTotalPoint] = useState(0);
  const [levelProgress, setLevelProgress] = useState();
  const [pointsHistory, setPointsHistory] = useState([]);
  const [totalPointLoading, setTotalPointLoading] = useState(false);
  const [levelProgressLoading, setLevelProgressLoading] = useState(false);
  const [pointHistoryLoading, setPointHistoryLoading] = useState(false);

  const fetchPointsHistory = async (page = 1) => {
    setPointHistoryLoading(true);
    try {
      const res = await axios.get(GET_USER_POINTS_HISTORY_API, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("bearer_token")}`,
        },
        params: { page },
      });

      if (res.data) {
        setPointHistoryLoading(false);
        setPagination(res.data.meta.pagination);
        setPointsHistory(res.data.data || []);
      }
    } catch (error) {
      setPointHistoryLoading(false);
      console.error("Failed to fetch points history:", error);
    }
  };

  const fetchProgress = async () => {
    setLevelProgressLoading(true);
    try {
      const res = await axios.get(GET_USER_PROGRESS_API, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("bearer_token")}`,
        },
      });
      if (res.data) {
        setLevelProgressLoading(false);
        setLevelProgress(res.data.data || []);
      }
    } catch (error) {
      setLevelProgressLoading(false);
      console.error("Failed to fetch level progress:", error);
    }
  };

  const fetchTotalPoints = async () => {
    setTotalPointLoading(true);
    try {
      const res = await axios.get(GET_USER_POINTS_API, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("bearer_token")}`,
        },
      });
      if (res.data) {
        setTotalPointLoading(false);
        setTotalPoint(res.data.data || 0);
      }
    } catch (error) {
      setTotalPointLoading(false);
      console.error("Failed to fetch total points:", error);
    }
  };

  const handlePageClick = (e) => {
    fetchPointsHistory(e.selected + 1);
  };

  useEffect(() => {
    fetchTotalPoints();
    fetchProgress();
    fetchPointsHistory();
  }, []);

  return (
    <div className="flex flex-col w-full h-fit gap-[24px] text-black">
      <m.div
        className="flex w-full gap-[24px]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <TotalPointCard totalPoint={totalPoint} isLoading={totalPointLoading} />
        {/* <AvailablePointCard />
        <ExampleCard /> */}
      </m.div>

      <m.div
        className="flex flex-col border border-[#F7F8F9] w-full p-[24px] bg-white shadow-[0_8px_16px_-4px_rgba(23,23,24,0.04)] gap-[24px] rounded-2xl"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <LoadingInComponent isLoading={levelProgressLoading}>
          {levelProgress && (
            <>
              <m.div
                className="flex w-full justify-between"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                <div className="flex gap-[12px]">
                  {/* TODO: Fix when ready */}
                  {/* <div className="bg-[#D9D9D9] w-[60px] h-[60px]"></div> */}
                  <div>
                    <p className="">Current Level</p>
                    <h1 className="font-[600] text-[25px]">
                      Level {levelProgress.level}
                    </h1>
                  </div>
                </div>
                {levelProgress && levelProgress.max_reached ? (
                  <div className="flex flex-col text-end">
                    <p className="">Points</p>
                    <h1 className="font-[600] text-[25px]">
                      {levelProgress.current_points}
                    </h1>
                  </div>
                ) : (
                  <div className="flex flex-col text-end">
                    <p className="">Points to Next Level</p>
                    <h1 className="font-[600] text-[25px]">
                      {levelProgress.current_points}/
                      {levelProgress.points_required}
                    </h1>
                  </div>
                )}
              </m.div>
              <div className="py-[40px]">
                <div className="relative h-2 bg-gray-300 rounded w-full">
                  <div
                    className="absolute h-2 bg-blue-700 rounded w-full"
                    style={{ width: `${levelProgress.progress_percent}%` }}
                  ></div>
                  <div className="absolute flex flex-col top-[-17px] left-[2%] items-center justify-center gap-[8px]">
                    <Icon
                      icon="ph:check-circle-fill"
                      className="w-[40px] h-[40px] bg-blue-700 text-white rounded-full p-[10px]"
                    />
                    <p className="">Level {levelProgress.level}</p>
                  </div>
                  {levelProgress && levelProgress.max_reached ? (
                    <div className="absolute flex flex-col top-[-17px] right-[2%] items-center justify-center gap-[8px]">
                      <Icon
                        icon="ph:check-circle-fill"
                        className="w-[40px] h-[40px] bg-blue-700 text-white rounded-full p-[10px]"
                      />
                      <p className="">Max Level</p>
                    </div>
                  ) : (
                    <div className="absolute flex flex-col top-[-17px] right-[2%] items-center justify-center gap-[8px]">
                      <Icon
                        icon="fa-solid:dot-circle"
                        className="w-[40px] h-[40px] bg-gray-300 text-[#636C7E] rounded-full p-[10px]"
                      />
                      <p className="">Level {levelProgress.next_level}</p>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </LoadingInComponent>
      </m.div>
      {/* Points History */}
      <m.div
        className="flex flex-col border border-[#F7F8F9] w-full p-[24px] bg-white shadow-[0_8px_16px_-4px_rgba(23,23,24,0.04)] gap-[24px] rounded-2xl"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h1 className="font-[600] text-[25px]">Point History</h1>
        <LoadingInComponent isLoading={pointHistoryLoading}>
          {pointsHistory && pointsHistory ? (
            <div>
              <div className="flex flex-col w-full gap-[12px]">
                {!pointHistoryLoading && (
                  <div className="grid grid-cols-6 gap-[12px] w-full justify-between">
                    <h2 className="w-full text-[#636C7E]">Date</h2>
                    <h2 className="w-full text-[#636C7E]">From</h2>
                    <h2 className="w-full text-[#636C7E]">To</h2>
                    <h2 className="w-full text-[#636C7E]">Type</h2>
                    <h2 className="w-full text-[#636C7E]">Remarks</h2>
                    <h2 className="w-full text-[#636C7E] text-end">Points</h2>
                  </div>
                )}
                {pointsHistory &&
                  pointsHistory.map((point, index) => (
                    <m.div
                      className="grid grid-cols-6 gap-[12px] w-full justify-between"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 1 }}
                      key={index}
                    >
                      <p className="text-start">{point.created_at}</p>
                      <p>{point.meta_from ? point.meta_from : "-"}</p>
                      <p>{point.meta_to ? point.meta_to : "-"}</p>
                      <p>{point.meta_type ? point.meta_type : "-"}</p>
                      <p>{point.meta_remark ? point.meta_remark : "-"}</p>
                      <p className="text-end">{point.amount}</p>
                    </m.div>
                  ))}
              </div>
              <div className="flex w-full justify-between mt-[12px]">
                {/* TODO: Add in get number of records per-page */}
                {/* <div className="flex items-center gap-[16px]">
              <select
                id="per_page"
                name="per_page"
                className="border border-[#EFF0F2] rounded-xl py-[10px] px-[12px]"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
              </select>
              <p>Showing page 1 of 1</p>
            </div> */}
                {pagination && pagination.total_pages > 1 && (
                  <div>
                    <ReactPaginate
                      onPageChange={(e) => handlePageClick(e)}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={1}
                      pageCount={pagination.total_pages}
                      forcePage={pagination.page - 1}
                      breakLabel="..."
                      previousLabel={
                        <Icon
                          icon="ph:caret-left"
                          className="w-[20px] h-[20px]"
                        />
                      }
                      nextLabel={
                        <Icon
                          icon="ph:caret-right"
                          className="w-[20px] h-[20px]"
                        />
                      }
                      nextClassName="paginator-action-btn flex border border-[#EFF0F2] bg-white rounded-lg items-center justify-center"
                      previousClassName="paginator-action-btn flex border border-[#EFF0F2] bg-white rounded-lg items-center justify-center"
                      containerClassName="flex gap-[4px] items-center justify-center"
                      activeClassName="paginator-action-btn flex border border-blue-600 bg-white rounded-lg items-center justify-center"
                      pageClassName="paginator-action-btn flex border border-[#EFF0F2] bg-white rounded-lg items-center justify-center hover:border-blue-600"
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              {!pointHistoryLoading && (
                <div className="w-full h-full">
                  <h1 className="text-[26px] text-center text-[#636C7E]">
                    No points history found.
                  </h1>
                </div>
              )}
            </>
          )}
        </LoadingInComponent>
      </m.div>
    </div>
  );
};

export default PointHistory;
