import { Icon } from "@iconify/react/dist/iconify.js";
import React, { createContext, useContext, useState } from "react";
import { AnimatePresence, motion as m } from "framer-motion";

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

// Define the Toast component
const Toast = ({ message, isVisible, type }) => {
  let bdColor = "";
  let icon = "";

  if (type === "success") {
    bdColor = "green";
    icon = "ei:check";
  } else if (type === "error") {
    bdColor = "red";
    icon = "charm:circle-cross";
  } else {
    bdColor = "white";
    icon = "tabler:alert-circle";
  }

  return (
    <AnimatePresence>
      {isVisible && (
        <m.div
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
          transition={{ duration: 1 }}
          className={`fixed bottom-[20px] right-[20px] bg-white border-[1px] border-solid border-${bdColor} text-black text-[16px] p-[24px] rounded-[10px] flex items-center gap-[24px] z-[2000]`}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "white",
            border: `1px solid ${bdColor}`,
            fontSize: "16px",
            color: "black",
            padding: "24px",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            gap: "24px",
            zIndex: 2000,
          }}
        >
          <Icon icon={icon} className={`w-[20px] h-[20px] color-${bdColor}`} />
          {message}
        </m.div>
      )}
    </AnimatePresence>
  );
};

export const ToastProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [toastType, setToastType] = useState("");

  const showToast = (msg, type) => {
    setToastType(type);
    setMessage(msg);
    setIsVisible(true);
    setTimeout(() => setIsVisible(false), 5000);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Toast message={message} isVisible={isVisible} type={toastType} />
    </ToastContext.Provider>
  );
};
