import React from "react";

const Button = ({
  type,
  children,
  variant = "solid_primary",
  className,
  onClick,
  disabled,
}) => {
  const variants = {
    solid_neutral: "bg-black text-white rounded-xl py-[8px]",
    solid_primary:
      "bg-[#365BC9] text-white rounded-xl hover:bg-[#E7FA2A] hover:text-black focus:bg-[#203779] py-[8px]",
    solid_secondary:
      "bg-[#E7FA2A] text-black rounded-xl hover:bg-[#365BC9] hover:text-white focus:bg-[#8B9619] focus:text-black py-[8px]",
    solid_info:
      "bg-[#14A3FA] text-white rounded-xl hover:bg-[#1082C8] focus:bg-[#0C6296] py-[8px]",
    solid_success:
      "bg-[#22C55E] text-white rounded-xl hover:bg-[#1B9E4B] focus:bg-[#147638] py-[8px]",
    solid_warning:
      "bg-[#FAAB14] text-black rounded-xl hover:bg-[#C88910] focus:bg-[#96670C] py-[8px]",
    solid_error:
      "bg-[#FF3030] text-white rounded-xl hover:bg-[#CD2626] focus:bg-[#9C1D1D] py-[8px]",
    solid_disabled:
      "bg-[#F7F8F9] text-[#A1A7B2] rounded-xl py-[8px] cursor-default",

    outline_neutral:
      "bg-none text-black border border-[#D0D3D8] rounded-xl hover:bg-[#F7F8F9] hover:border-[#0A0B0D] focus:bg-[#EFF0F2] focus:border-[#0A0B0D] py-[8px]",
    outline_primary:
      "bg-none text-[#365BC9] border border-[#365BC9] rounded-xl hover:bg-[#F5F7FD] hover:border-[#2B49A1] focus:bg-[#EBEFFA] focus:border-[#2B49A1] py-[8px]",
    outline_white: "bg-none border border-white text-white rounded-xl py-[8px]",
    outline_info:
      "bg-none text-[#14A3FA] border border-[#14A3FA] rounded-xl hover:bg-[#F3FAFF] hover:border-[#1082C8] hover:text-[#1082C8] focus:bg-[#E8F6FF] focus:border-[#1082C8] focus:text-[#14A3FA] py-[8px]",
    outline_success:
      "bg-none text-[#22C55E] border border-[#22C55E] rounded-xl hover:bg-[#F4FCF7] hover:border-[#1B9E4B] hover:text-[#1B9E4B] focus:bg-[#E9F9EF] focus:border-[#1B9E4B] focus:text-[#22C55E] py-[8px]",
    outline_warning:
      "bg-none text-[#FAAB14] border border-[#FAAB14] rounded-xl hover:bg-[#FFFBF3] hover:border-[#C88910] hover:text-[#C88910] focus:bg-[#FFF7E8] focus:border-[#C88910] focus:text-[#FAAB14] py-[8px]",
    outline_error:
      "bg-none text-[#FF3030] border border-[#FF3030] rounded-xl hover:bg-[#FFF4F4] hover:border-[#CD2626] hover:text-[#CD2626] focus:bg-[#FFEAEA] focus:border-[#CD2626] focus:text-[#FF3030] py-[8px]",
    outline_disabled:
      "bg-none text-[#A1A7B2] border border-[#D0D3D8] rounded-xl py-[8px] cursor-default",

    texto_neutral:
      "bg-none rounded-xl text-black hover:bg-[#F7F8F9] focus:bg-[#EFF0F2] py-[8px]",
    texto_primary:
      "bg-none rounded-xl text-[#365BC9] hover:bg-[#F5F7FD] hover:text-[#2B49A1] focus:bg-[#EBEFFA] py-[8px]",
    texto_white: "bg-none rounded-xl text-white py-[8px]",
    texto_info:
      "bg-none rounded-xl text-[#14A3FA] hover:bg-[#F3FAFF] hover:text-[#2B49A1] focus:bg-[#E8F6FF] py-[8px]",
    texto_success:
      "bg-none rounded-xl text-[#22C55E] hover:bg-[#F4FCF7] hover:text-[#1B9E4B] focus:bg-[#E9F9EF] py-[8px]",
    texto_warning:
      "bg-none rounded-xl text-[#FAAB14] hover:bg-[#FFFBF3] hover:text-[#C88910] focus:bg-[#FFF7E8] py-[8px]",
    texto_error:
      "bg-none rounded-xl text-[#FF3030] hover:bg-[#FFF4F4] hover:text-[#CD2626] focus:bg-[#FFEAEA] py-[8px]",
    texto_disabled: "bg-none rounded-xl text-[#A1A7B2] py-[8px] cursor-default",

    discord: "bg-[#5865F2] text-white rounded-xl hover:bg-[#4E5AC7] py-[8px]",

    staticlinks: "bg-none text-[#365BC9] underline text-start",
    links: "bg-none text-[#365BC9] hover:underline text-start",
  };

  const classes = `${variants[variant]} ${className} min-w-[96px]`;

  return (
    <button
      className={classes}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  );
};

export default Button;
