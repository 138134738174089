import React from "react";

const LoginLayout = ({ children }) => {
  return (
    <div className="flex w-full h-full overflow-y-scroll no-scrollbar">
      {children}
      <div
        className="flex flex-col w-full h-full p-[64px] gap-[32px] justify-center bg-black"
        style={{
          backgroundImage: "url('/Images/onboarding-frame.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
        }}
      >
        <div className="grid grid-cols-2 w-full h-full text-white gap-[24px]">
          {/* <div className="flex flex-col w-full h-fit gap-[24px]">
            <img
              src="https://placehold.co/1600x900"
              className="w-full h-[165px] object-cover rounded-xl"
              alt="banner-images"
            />
            <img
              src="https://placehold.co/1600x900"
              className="w-full h-full object-cover rounded-xl"
              alt="banner-images"
            />
          </div>
          <div className="flex flex-col w-full h-fit gap-[24px]">
            <img
              src="https://placehold.co/1600x900"
              className="w-full h-full object-cover rounded-xl"
              alt="banner-images"
            />
            <img
              src="https://placehold.co/1600x900"
              className="w-full h-[165px] object-cover rounded-xl"
              alt="banner-images"
            />
          </div> */}
        </div>
        <div className="flex flex-col w-full h-fit gap-[8px]">
          <h1 className="w-full text-white font-[700] text-[31px]">
            Interact, Win Points & Redeem Prizes!
          </h1>
          <p className="text-white ">
            Join our Discord group to enjoy exciting and fun hackathon journey!
            Through the point system, attendees can earn points to redeem
            limited edition NASA SpaceApps swag pack items!
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
