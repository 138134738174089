import React, { useState } from "react";
import Button from "../../Components/Button";
import PersonalInfoForm from "../../Components/Forms/PersonalInfoForm";
import TeamInfoForm from "../../Components/Forms/TeamInfoForm";
import axios from "axios";
import {
  UPDATE_PERSONAL_INFO_API,
  UPDATE_TEAM_INFO_API,
  UPLOAD_IMAGE_API,
} from "../../Constants/api";
import { useToast } from "../../Components/Toast";
import UploadImage from "../../Components/UploadImage";
import { LoadingOverlay } from "../../Components/LoadingOverlay";
import { motion as m } from "framer-motion";

const Profile = ({ userInfo, setUserInfo, options, handleChange }) => {
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();

  // Avatar updates
  const handleAvatarChange = (value) => {
    setUserInfo({ ...userInfo, avatar: value });
  };

  const handleUpdateAvatar = (e) => {
    setLoading(true);
    e.preventDefault();
    axios
      .post(
        UPLOAD_IMAGE_API,
        { avatar: userInfo.avatar },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("bearer_token")}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        showToast("Avatar updated successfully", "success");
      })
      .catch((err) => {
        setLoading(false);
        showToast("Error updating avatar", "error");
      });
  };

  // Updating Personal and Team Info
  const handleUpdate = async (apiUrl, data) => {
    setLoading(true);
    try {
      await axios.put(apiUrl, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("bearer_token")}`,
        },
      });
      showToast("Information updated successfully", "success");
      setLoading(false);
    } catch (error) {
      showToast("Error updating information", "error");
    }
  };

  const handleUpdatePersonalInfo = (e) => {
    e.preventDefault();
    const { name, race_id, gender_id, district_id } = userInfo;
    handleUpdate(
      UPDATE_PERSONAL_INFO_API,
      { name, race_id, gender_id, district_id },
      "userInfoLoading"
    );
  };

  const handleUpdateTeamInfo = (e) => {
    e.preventDefault();
    const { school, occupation_id, category_id, discover_method_id } = userInfo;
    handleUpdate(
      UPDATE_TEAM_INFO_API,
      { school, occupation_id, category_id, discover_method_id },
      "teamInfoLoading"
    );
  };

  return (
    <div className="flex flex-col w-full h-fit gap-[24px] text-black">
      {loading && <LoadingOverlay />}
      {userInfo && (
        <m.div
          className="flex border border-[#F7F8F9] w-full h-full p-[24px] bg-white shadow-[0_8px_16px_-4px_rgba(23,23,24,0.04)] gap-[24px] rounded-2xl"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <div className="w-2/5">
            <h1 className="font-[700]">Profile Image</h1>
            <p className="">
              This image will be used as your profile picture in this platform.
            </p>
          </div>
          <div className="flex flex-col w-full h-full gap-[32px]">
            <div className="flex w-full gap-[12px]">
              <img
                src={userInfo.avatar ?? "/Images/defaultprofilepic.jpeg"}
                className="w-[72px] h-[72px] rounded-lg"
                alt="profile-pic"
              />
              <UploadImage handleChange={handleAvatarChange} />
            </div>
            <div className="flex w-full justify-end">
              <Button
                variant="solid_primary"
                className="px-[16px]"
                onClick={(e) => {
                  handleUpdateAvatar(e);
                }}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </m.div>
      )}
      {userInfo &&
        options.genderList.length > 0 &&
        options.raceList.length > 0 &&
        options.districtList.length > 0 && (
          <m.div
            className="flex border border-[#F7F8F9] w-full h-full p-[24px] bg-white shadow-[0_8px_16px_-4px_rgba(23,23,24,0.04)] gap-[24px] rounded-2xl"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <div className="w-2/5">
              <h1 className="font-[700]">Personal Information</h1>
              <p className="">
                This information will your personal information in this
                platform.
              </p>
            </div>
            <div className="flex flex-col w-full gap-[32px]">
              <PersonalInfoForm
                personalInfo={userInfo}
                handleChange={handleChange}
                districtOptions={options.districtList}
                genderOptions={options.genderList}
                raceOptions={options.raceList}
              />
              <div className="flex w-full justify-end">
                <Button
                  variant="solid_primary"
                  className="px-[16px]"
                  onClick={(e) => {
                    handleUpdatePersonalInfo(e);
                  }}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </m.div>
        )}

      {userInfo &&
        options.occupationOption.length > 0 &&
        options.categoryOption.length > 0 &&
        options.discoverMethodOption.length > 0 && (
          <m.div
            className="flex border border-[#F7F8F9] w-full h-full p-[24px] bg-white shadow-[0_8px_16px_-4px_rgba(23,23,24,0.04)] gap-[24px] rounded-2xl"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <div className="w-2/5">
              <h1 className="font-[700]">Team Information</h1>
              <p className="">
                This information will your team information in this platform.
              </p>
            </div>
            <div className="flex flex-col w-full gap-[32px]">
              <TeamInfoForm
                teamInfo={userInfo}
                handleChange={handleChange}
                occupationOption={options.occupationOption}
                categoryOption={options.categoryOption}
                discoverMethodOption={options.discoverMethodOption}
              />
              <div className="flex w-full justify-end">
                <Button
                  variant="solid_primary"
                  className="px-[16px]"
                  onClick={(e) => {
                    handleUpdateTeamInfo(e);
                  }}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </m.div>
        )}
    </div>
  );
};

export default Profile;
