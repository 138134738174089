import React from "react";
import TextboxSet from "../TextboxSet";
import DropdownSet from "../DropdownSet";

const PersonalInfoForm = ({
  personalInfo,
  handleChange,
  districtOptions,
  genderOptions,
  raceOptions,
}) => {
  return (
    <div className="flex flex-col w-full gap-[16px]">
      <TextboxSet
        name="name"
        label="Full Name following your IC"
        placeholder="Your Name Based On IC"
        value={personalInfo?.name ?? ""}
        onChange={handleChange}
        required
      />
      {raceOptions && (
        <DropdownSet
          label="Race"
          dataName="race_id"
          value={personalInfo.race_id}
          options={raceOptions}
          required
          onChange={handleChange}
        />
      )}
      {genderOptions && (
        <DropdownSet
          label="Gender"
          dataName="gender_id"
          value={personalInfo.gender_id}
          options={genderOptions}
          required
          onChange={handleChange}
        />
      )}
      {districtOptions && (
        <DropdownSet
          label="District"
          dataName="district_id"
          value={personalInfo.district_id}
          options={districtOptions}
          required
          onChange={handleChange}
        />
      )}
    </div>
  );
};

export default PersonalInfoForm;
