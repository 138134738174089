import React from "react";
import TextboxSet from "../TextboxSet";
import DropdownSet from "../DropdownSet";

const TeamInfoForm = ({
  teamInfo,
  handleChange,
  discoverMethodOption,
  categoryOption,
  occupationOption,
}) => {
  return (
    <div className="flex flex-col w-full gap-[16px]">
      <TextboxSet
        name="school"
        label="School"
        placeholder="Your School Name"
        value={teamInfo.school ?? ""}
        onChange={handleChange}
      />
      <DropdownSet
        label="Occupation"
        dataName="occupation_id"
        value={teamInfo.occupation_id}
        options={occupationOption}
        required
        onChange={handleChange}
      />
      <DropdownSet
        label="Category"
        dataName="category_id"
        value={teamInfo.category_id}
        options={categoryOption}
        required
        onChange={handleChange}
      />
      <DropdownSet
        label="How did you find out NASA Space Apps Sarawak?"
        dataName="discover_method_id"
        value={teamInfo.discover_method_id}
        options={discoverMethodOption}
        required
        onChange={handleChange}
      />
    </div>
  );
};

export default TeamInfoForm;
