import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";

const Footer = () => {
  return (
    <div className="flex w-full justify-between text-[#636C7E] mb-[32px]">
      {/* Copyright */}
      <p className="text-[13px]">
        Copyright {`${new Date().getFullYear()}`} @ SPACE APPS SARAWAK
      </p>
      {/* TODO: DISPLAY WHEN READY */}
      {/* Social Media Redirection */}
      {/* <div className="flex gap-[12px]">
        <a
          href="https://www.facebook.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon="ic:round-facebook" className="w-[16px] h-[16px]" />
        </a>
        <a
          href="https://www.instagram.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon="iconoir:instagram" className="w-[16px] h-[16px]" />
        </a>
        <a
          href="https://www.tiktok.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon="ic:round-tiktok" className="w-[16px] h-[16px]" />
        </a>
      </div> */}
    </div>
  );
};

export default Footer;
