import { useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";

const TextboxSet = ({
  type = "text",
  name,
  variant = "default",
  placeholder,
  className,
  value,
  label,
  disabled,
  required,
  onChange,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const variants = {
    default:
      "border border-[#E0E2E5] rounded-lg hover:border-black focus:border-[#365BC9] focus:outline-[#365BC9] focus:shadow-[0px_0px_4px_0px_#6200EE80] disabled:border-[#D0D3D8] disabled:hover:border-[#D0D3D8] disabled:placeholder:text-[#D0D3D8]",
  };

  const label_variants = {
    default: "focused:text-[#365BC9]",
  };

  let label_classes = `flex ${label_variants[variant]}`;
  let text_classes = `${variants[variant]} ${className} py-[8px] px-[16px]`;
  return (
    <>
      {type === "password" ? (
        <div className="flex flex-col gap-[6px]">
          <h2
            className={`${label_classes} ${disabled ? "text-[#D0D3D8]" : ""}`}
          >
            {label} {required && <span className="text-red-500">*</span>}
          </h2>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              name={name}
              className={`${text_classes} w-full pr-[40px]`}
              placeholder={disabled ? "Disabled" : placeholder}
              value={value}
              onChange={onChange}
            />
            <Icon
              icon={showPassword ? "mdi:eye-outline" : "mdi:eye-off-outline"}
              className="absolute top-[4px] right-[5px] w-[20px] h-[20px] mt-2 mr-2" // adjust margins as needed
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-[6px]">
          <h2
            className={`${label_classes} ${disabled ? "text-[#D0D3D8]" : ""}`}
          >
            {label} {required && <span className="text-red-500">*</span>}
          </h2>
          <input
            type={type === "number" ? null : type}
            name={name}
            className={text_classes}
            placeholder={disabled ? "Disabled" : placeholder}
            value={value}
            disabled={disabled}
            onChange={(e) => {
              if (type === "number") {
                const regex = /^[0-9]*$/;
                if (regex.test(e.target.value) || e.target.value === "") {
                  onChange(e);
                }
              } else {
                onChange(e);
              }
            }}
          />
        </div>
      )}
    </>
  );
};

export default TextboxSet;
