import { Navigate, useRoutes } from "react-router-dom";
import {
  DISCORD_CONNECT,
  FORGET_EMAIL,
  FORGET_PASSWORD,
  LOGIN,
  MOBILE_VERIFICATION,
  PLATFORM,
  RECOVER_EMAIL,
  REGISTER,
  RESET_PASSWORD,
  RESET_PASSWORD_COMPLETE,
  RESET_PASSWORD_EMAIL,
  VERIFY_EMAIL,
} from "./path";
import Login from "../Page/Login";
import Register from "../Page/Register/Register";
import VerifyEmail from "../Page/Register/VerifyEmail";
import DiscordConnect from "../Page/DiscordConnect";
import ForgetEmail from "../Page/ForgetEmail/ForgetEmail";
import PwReset from "../Page/ResetPassword/PwReset";
import PwResetEmail from "../Page/ResetPassword/PwResetEmail";
import PwResetComplete from "../Page/ResetPassword/PwResetComplete";
import PlatformFrame from "../Page/Platform/PlatformFrame";
import RecoverEmail from "../Page/ForgetEmail/RecoverEmail";
import MobileVerification from "../Page/ForgetEmail/MobileVerification";
import ForgetPw from "../Page/ResetPassword/ForgetPw";

export default function Router() {
  const isAuthenticated = () => sessionStorage.getItem("bearer_token");
  const isDiscordConnected = () => sessionStorage.getItem("discord_required");

  const routes = [
    {
      path: LOGIN,
      element: isAuthenticated() ? (
        isDiscordConnected() ? (
          <Navigate to={DISCORD_CONNECT} />
        ) : (
          <Navigate to={PLATFORM} />
        )
      ) : (
        <Login />
      ),
    },
    {
      path: REGISTER,
      element: isAuthenticated() ? (
        isDiscordConnected() ? (
          <Navigate to={DISCORD_CONNECT} />
        ) : (
          <Navigate to={PLATFORM} />
        )
      ) : (
        <Register />
      ),
    },
    {
      path: VERIFY_EMAIL,
      element: isAuthenticated() ? (
        isDiscordConnected() ? (
          <Navigate to={DISCORD_CONNECT} />
        ) : (
          <Navigate to={PLATFORM} />
        )
      ) : (
        <VerifyEmail />
      ),
    },
    {
      path: FORGET_PASSWORD,
      element: isAuthenticated() ? (
        isDiscordConnected() ? (
          <Navigate to={DISCORD_CONNECT} />
        ) : (
          <Navigate to={PLATFORM} />
        )
      ) : (
        <ForgetPw />
      ),
    },
    {
      path: FORGET_EMAIL,
      element: isAuthenticated() ? (
        isDiscordConnected() ? (
          <Navigate to={DISCORD_CONNECT} />
        ) : (
          <Navigate to={PLATFORM} />
        )
      ) : (
        <ForgetEmail />
      ),
    },
    {
      path: RECOVER_EMAIL,
      element: isAuthenticated() ? (
        isDiscordConnected() ? (
          <Navigate to={DISCORD_CONNECT} />
        ) : (
          <Navigate to={PLATFORM} />
        )
      ) : (
        <RecoverEmail />
      ),
    },
    {
      path: RESET_PASSWORD,
      element: isAuthenticated() ? (
        isDiscordConnected() ? (
          <Navigate to={DISCORD_CONNECT} />
        ) : (
          <Navigate to={PLATFORM} />
        )
      ) : (
        <PwReset />
      ),
    },
    {
      path: RESET_PASSWORD_EMAIL,
      element: isAuthenticated() ? (
        isDiscordConnected() ? (
          <Navigate to={DISCORD_CONNECT} />
        ) : (
          <Navigate to={PLATFORM} />
        )
      ) : (
        <PwResetEmail />
      ),
    },
    {
      path: RESET_PASSWORD_COMPLETE,
      element: isAuthenticated() ? (
        isDiscordConnected() ? (
          <Navigate to={DISCORD_CONNECT} />
        ) : (
          <Navigate to={PLATFORM} />
        )
      ) : (
        <PwResetComplete />
      ),
    },
    {
      path: MOBILE_VERIFICATION,
      element: isAuthenticated() ? (
        isDiscordConnected() ? (
          <Navigate to={DISCORD_CONNECT} />
        ) : (
          <Navigate to={PLATFORM} />
        )
      ) : (
        <MobileVerification />
      ),
    },
    {
      path: VERIFY_EMAIL,
      element: isAuthenticated() ? (
        isDiscordConnected() ? (
          <Navigate to={DISCORD_CONNECT} />
        ) : (
          <Navigate to={PLATFORM} />
        )
      ) : (
        <VerifyEmail />
      ),
    },
    {
      path: DISCORD_CONNECT,
      element: isAuthenticated() ? (
        isDiscordConnected() ? (
          <DiscordConnect />
        ) : (
          <Navigate to={PLATFORM} />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
    {
      path: PLATFORM,
      element: isAuthenticated() ? (
        isDiscordConnected() ? (
          <Navigate to={DISCORD_CONNECT} />
        ) : (
          <PlatformFrame />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
  ];

  // return useRoutes(status === "connected" ? routes : initializingRoutes);
  return useRoutes(routes);
}
