import React from "react";
import Button from "../../../Components/Button";
import TeamInfoForm from "../../../Components/Forms/TeamInfoForm";
import { useToast } from "../../../Components/Toast";

const RegisterPThree = ({
  registerStep,
  setRegisterStep,
  formData,
  handleChange,
  discoverMethodOption,
  categoryOption,
  occupationOption,
}) => {
  const { showToast } = useToast();

  return (
    <form className="flex flex-col gap-[32px]">
      <div className="flex flex-col gap-[2px]">
        <h1 className="font-[600] text-[39px]">Register Account</h1>
        <p className="">
          Create your account and provide your personal information below.
        </p>
      </div>
      <div className="flex flex-col gap-[16px]">
        <div>
          <h2 className="font-[700] text-[16px]">
            Step 3/5 - Team Information
          </h2>
        </div>
        <TeamInfoForm
          teamInfo={formData}
          handleChange={handleChange}
          discoverMethodOption={discoverMethodOption}
          categoryOption={categoryOption}
          occupationOption={occupationOption}
        />
      </div>
      <div className="flex gap-[16px]">
        <Button
          variant="outline_neutral"
          className="px-[33px] "
          onClick={() => {
            setRegisterStep(registerStep - 1);
          }}
        >
          Back
        </Button>
        <Button
          variant="solid_primary"
          className="px-[33px] "
          onClick={(e) => {
            e.preventDefault();
            if (
              formData.occupation_id &&
              formData.category_id &&
              formData.discover_method_id
            ) {
              setRegisterStep(registerStep + 1);
            } else {
              showToast("Please fill in all the required fields.", "error");
            }
          }}
        >
          Next
        </Button>
      </div>
    </form>
  );
};

export default RegisterPThree;
