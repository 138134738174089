import { useState, useEffect } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Button from "../../Components/Button";
import Home from "./Home";
import Profile from "./Profile";
import PointHistory from "./PointHistory";
import axios from "axios";
import {
  GET_USER_INFO_API,
  CATEGORY_OPTIONS_API,
  DISCOVER_METHOD_OPTIONS_API,
  DISTRICT_OPTIONS_API,
  GENDER_OPTIONS_API,
  OCCUPATION_OPTIONS_API,
  RACE_OPTIONS_API,
} from "../../Constants/api";
import { useToast } from "../../Components/Toast";
import { LoadingOverlay } from "../../Components/LoadingOverlay";

const PlatformFrame = () => {
  const tabs = [
    {
      id: 1,
      name: "home",
      title: "Home",
    },
    {
      id: 2,
      name: "profile",
      title: "Profile",
    },
    {
      id: 1,
      name: "point-history",
      title: "Point History",
    },
  ];

  const [options, setOptions] = useState({
    genderList: [],
    raceList: [],
    districtList: [],
    discoverMethodOption: [],
    categoryOption: [],
    occupationOption: [],
  });
  const [activeTab, setActiveTab] = useState(
    sessionStorage.getItem("activeTab") ?? "home"
  );
  const [userInfo, setUserInfo] = useState();
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();

  const handleChange = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const fetchData = (apiUrl, optionKey) => {
    axios
      .get(apiUrl)
      .then((res) => {
        setOptions((prev) => ({ ...prev, [optionKey]: res.data.data }));
      })
      .catch((error) => {
        console.error(`Error fetching ${optionKey} options:`, error);
      });
  };

  const fetchUserInfo = () => {
    axios
      .get(GET_USER_INFO_API, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("bearer_token")}`,
        },
      })
      .then((res) => {
        setUserInfo(res.data.data);
      })
      .catch((err) => {
        console.error("Failed to fetch user info:", err);
        showToast("Failed to fetch user info", "error");
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchData(RACE_OPTIONS_API, "raceList");
    fetchData(GENDER_OPTIONS_API, "genderList");
    fetchData(DISTRICT_OPTIONS_API, "districtList");
    fetchData(OCCUPATION_OPTIONS_API, "occupationOption");
    fetchData(CATEGORY_OPTIONS_API, "categoryOption");
    fetchData(DISCOVER_METHOD_OPTIONS_API, "discoverMethodOption");
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (
      userInfo &&
      options.categoryOption.length > 0 &&
      options.occupationOption.length > 0 &&
      options.discoverMethodOption.length > 0 &&
      options.districtList.length > 0 &&
      options.genderList.length > 0 &&
      options.raceList.length > 0 &&
      options.categoryOption.length > 0 &&
      options.discoverMethodOption.length > 0 &&
      options.occupationOption.length > 0
    ) {
      setLoading(false);
    }
  }, [userInfo, options]);

  return (
    <div
      className="relative flex flex-col w-full h-screen items-center text-white gap-[43px] overflow-y-scroll no-scrollbar"
      style={{
        backgroundImage: "url('/Images/platform-bg.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 400px",
        backgroundPosition: "top",
        backgroundAttachment: "local",
      }}
    >
      {loading && <LoadingOverlay />}
      <Header />
      <div className="flex flex-col container mx-auto grow">
        <div className="flex flex-col w-full h-full gap-[48px] mb-[108px]">
          <div className="flex flex-col gap-[2px]">
            <h1 className="text-[61px] font-[600]">
              NASA Space Apps Sarawak {`${new Date().getFullYear()}`}
            </h1>
            {userInfo && <p className="">Welcome back {userInfo.name}.</p>}
          </div>
          <div className="flex flex-col gap-[24px]">
            <div className="flex gap-[16px]">
              {tabs.map((tab) => (
                <Button
                  key={tab.id + " " + tab.name}
                  variant={
                    activeTab === tab.name ? "solid_secondary" : "texto_white"
                  }
                  className="px-[16px]"
                  onClick={() => {
                    setActiveTab(tab.name);
                    sessionStorage.setItem("activeTab", tab.name);
                  }}
                >
                  {tab.title}
                </Button>
              ))}
            </div>
            {activeTab === "home" && userInfo && <Home homeInfo={userInfo} />}
            {activeTab === "profile" && userInfo && (
              <Profile
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                options={options}
                handleChange={handleChange}
              />
            )}
            {activeTab === "point-history" && <PointHistory />}
          </div>
          <div className="flex w-full h-full items-center justify-center">
            {/* <h1 className="text-black text-[64px]">Coming Soon!</h1> */}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PlatformFrame;
