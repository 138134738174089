import { useState, useEffect } from "react";
import Button from "../../Components/Button";
import RegisterPOne from "./RegisterParts/RegisterPOne";
import RegisterPTwo from "./RegisterParts/RegisterPTwo";
import RegisterPThree from "./RegisterParts/RegisterPThree";
import RegisterPFour from "./RegisterParts/RegisterPFour";
import RegisterPFive from "./RegisterParts/RegisterPFive";
import LoginLayout from "../../Components/LoginLayout";
import { useNavigate } from "react-router-dom";
import {
  REGISTER_API,
  DISTRICT_OPTIONS_API,
  GENDER_OPTIONS_API,
  RACE_OPTIONS_API,
  DISCOVER_METHOD_OPTIONS_API,
  CATEGORY_OPTIONS_API,
  OCCUPATION_OPTIONS_API,
} from "../../Constants/api.js";
import axios from "axios";
import { LoadingOverlay } from "../../Components/LoadingOverlay.jsx";
import { useToast } from "../../Components/Toast.jsx";

const Register = () => {
  const [registerStep, setRegisterStep] = useState(1);
  const [loading, setLoading] = useState(false);
  // Part 2 props
  const [districtOptions, setDistrictOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [raceOptions, setRaceOptions] = useState([]);
  // Part 3 props
  const [discoverMethodOption, setDiscoverMethodOption] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [occupationOption, setOccupationOption] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    ic: "",
    email: "",
    phone_no: "",
    password: "",
    confirmPassword: "",
    discover_method_id: "",
    race_id: "",
    gender_id: "",
    district_id: "",
    school: "",
    occupation_id: "",
    category_id: "",
    guardian_name: "",
    guardian_email: "",
    guardian_phone_no: "",
    tnc: false,
    guardian_permission: false,
    public_display_agreement: false,
  });

  const { showToast } = useToast();
  const navigate = useNavigate();

  const handleRegister = (e) => {
    setLoading(true);
    axios({
      method: "post",
      url: REGISTER_API,
      data: {
        ic: formData.ic,
        name: formData.name,
        email: formData.email,
        phone_no: formData.phone_no,
        password: formData.password,
        discover_method_id: formData.discover_method_id,
        occupation_id: formData.occupation_id,
        race_id: formData.race_id,
        gender_id: formData.gender_id,
        district_id: formData.district_id,
        school: formData.school,
        category_id: formData.category_id,
        guardian_name: formData.guardian_name,
        guardian_email: formData.guardian_email,
        guardian_phone_no: formData.guardian_phone_no,
        tnc: formData.tnc,
        guardian_permission: formData.guardian_permission,
        public_display_agreement: formData.public_display_agreement,
      },
    })
      .then((res) => {
        setLoading(false);
        setRegisterStep(registerStep + 1);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.data.email) {
          showToast(error.response.data.data.email, "error");
        }
        if (error.response.data.data.ic) {
          showToast(error.response.data.data.ic, "error");
        }
        if (error.response.data.data.guardian_email) {
          showToast(error.response.data.data.guardian_email, "error");
        }
      });
  };

  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  useEffect(() => {
    axios
      .get(DISTRICT_OPTIONS_API)
      .then((res) => {
        setDistrictOptions(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching district options:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(GENDER_OPTIONS_API)
      .then((res) => {
        setGenderOptions(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(RACE_OPTIONS_API)
      .then((res) => {
        setRaceOptions(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching race options:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(DISCOVER_METHOD_OPTIONS_API)
      .then((res) => {
        setDiscoverMethodOption(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching discovery method options:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(CATEGORY_OPTIONS_API)
      .then((res) => {
        setCategoryOption(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching category options:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(OCCUPATION_OPTIONS_API)
      .then((res) => {
        setOccupationOption(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching category options:", error);
      });
  }, []);

  return (
    <LoginLayout
      children={
        <div className="relative flex flex-col w-full max-w-[600px] h-full border-r gap-[48px] p-[64px]">
          {loading && <LoadingOverlay />}
          <img
            src="/Images/logo.png"
            className="w-[122px] h-[48px]"
            alt="logo"
          />
          {registerStep === 1 && (
            <RegisterPOne
              registerStep={registerStep}
              setRegisterStep={setRegisterStep}
              formData={formData}
              handleChange={handleChange}
            />
          )}
          {registerStep === 2 && (
            <RegisterPTwo
              registerStep={registerStep}
              setRegisterStep={setRegisterStep}
              formData={formData}
              handleChange={handleChange}
              raceOptions={raceOptions}
              genderOptions={genderOptions}
              districtOptions={districtOptions}
            />
          )}
          {registerStep === 3 && (
            <RegisterPThree
              registerStep={registerStep}
              setRegisterStep={setRegisterStep}
              formData={formData}
              handleChange={handleChange}
              discoverMethodOption={discoverMethodOption}
              categoryOption={categoryOption}
              occupationOption={occupationOption}
            />
          )}
          {registerStep === 4 && (
            <RegisterPFour
              registerStep={registerStep}
              setRegisterStep={setRegisterStep}
              formData={formData}
              handleChange={handleChange}
              handleRegister={handleRegister}
            />
          )}
          {registerStep === 5 && (
            <RegisterPFive
              registerStep={registerStep}
              setRegisterStep={setRegisterStep}
              registeredEmail={formData.email}
            />
          )}

          <p className="w-full bottom-[32px] font-[600]">
            Already have an account?{" "}
            <Button
              variant="links"
              onClick={() => {
                navigate("/");
              }}
            >
              Sign In here
            </Button>
          </p>
        </div>
      }
    />
  );
};

export default Register;
