import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoginLayout from "../../Components/LoginLayout";
import Button from "../../Components/Button";
import TextboxSet from "../../Components/TextboxSet";
import axios from "axios";
import { RESET_PASSWORD_API } from "../../Constants/api";
import { LoadingOverlay } from "../../Components/LoadingOverlay";
import { useToast } from "../../Components/Toast";

const PwReset = () => {
  const [loading, setLoading] = useState(false);
  const [resetPwForm, setResetPwForm] = useState({
    password: "",
    confirmPassword: "",
  });
  const { showToast } = useToast();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const navigate = useNavigate();

  const handleChange = (e) => {
    setResetPwForm({
      ...resetPwForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (resetPwForm.password && resetPwForm.confirmPassword) {
      if (resetPwForm.password === resetPwForm.confirmPassword) {
        setLoading(true);
        axios
          .post(RESET_PASSWORD_API, {
            password: resetPwForm.password,
            token: token,
          })
          .then((res) => {
            setLoading(false);
            navigate("/reset-password-complete");
          })
          .catch((err) => {
            setLoading(false);
            showToast(err.response.data.message);
          });
      } else {
        showToast("Passwords do not match.", "error");
      }
    } else {
      showToast("Please fill in all fields.", "error");
    }
  };

  return (
    <LoginLayout
      children={
        <form className="relative flex flex-col w-full max-w-[600px] h-full border-r gap-[48px] p-[64px]">
          {loading && <LoadingOverlay />}
          <img
            src="/Images/logo.png"
            className="w-[122px] h-[48px]"
            alt="logo"
          />
          <div className="flex flex-col gap-[32px]">
            <div>
              <h1 className="font-[600] text-[39px] gap-[2px]">
                Reset Password
              </h1>
              <p className="">Please enter the new password below.</p>
            </div>
            <TextboxSet
              type="password"
              name="password"
              label="Password"
              placeholder="Your Password"
              value={resetPwForm.password}
              onChange={handleChange}
              required
            />
            <TextboxSet
              type="password"
              name="confirmPassword"
              label="Confirm Password"
              placeholder="Your Password"
              value={resetPwForm.confirmPassword}
              onChange={handleChange}
              required
            />
            <Button
              variant="solid_primary"
              className="w-fit h-fit px-[24px]"
              onClick={(e) => handleResetPassword(e)}
            >
              Submit
            </Button>
          </div>
        </form>
      }
    />
  );
};

export default PwReset;
