import React, { useState, useEffect } from "react";
import Button from "./Button";

function CountdownTimer({ onClick }) {
  const initialMinutes = 1;
  const initialSeconds = 0;
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [isActive, setIsActive] = useState(true);

  const resetTimer = (e) => {
    setMinutes(initialMinutes);
    setSeconds(initialSeconds);
    // setIsActive(true);
    onClick(e, setIsActive);
  };

  useEffect(() => {
    let interval = null;

    if (isActive && (minutes > 0 || seconds > 0)) {
      interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds((seconds) => seconds - 1);
        } else if (minutes > 0 && seconds === 0) {
          setMinutes((minutes) => minutes - 1);
          setSeconds(59);
        }
      }, 1000);
    } else if (minutes === 0 && seconds === 0) {
      setIsActive(false);
    }

    return () => clearInterval(interval);
  }, [isActive, minutes, seconds]);

  return (
    <div>
      <div className="flex justify-between">
        {!isActive ? (
          <div>
            <p className="">
              Didn't receive?{" "}
              <Button
                variant="links"
                onClick={(e) => resetTimer(e)}
                className="disabled:hidden"
                disabled={isActive}
              >
                Resend Code.
              </Button>
            </p>
          </div>
        ) : (
          <div></div>
        )}
        <h1>
          {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </h1>
      </div>
    </div>
  );
}

export default CountdownTimer;
