import React from "react";

const ParticipantCard = ({ name, avatar, participated_year }) => {
  const yearOnly = new Date(participated_year).getFullYear();
  return (
    <div className="flex flex-col border border-[#F7F8F9] w-full p-[24px] bg-white rounded-2xl gap-[16px] shadow-[0_8px_16px_-4px_rgba(23,23,24,0.04)]">
      <div className="flex gap-[12px] items-center">
        <img
          src={avatar ?? "/Images/defaultprofilepic.jpeg"}
          className="w-[56px] h-[56px] rounded-lg"
          alt="profile-pic"
        />
        <div className="w-full">
          <h2 className="font-[600]">{name ?? "Participant Name"}</h2>
          <p className="w-fit rounded-md bg-[#F5F7FD] text-[#365BC9]">
            {`${yearOnly} Participant`}
          </p>
        </div>
      </div>
      {/* <div className="flex gap-[12px] items-center">
        <div className="w-fit">
          <div className="bg-[#D9D9D9] w-[30px] h-[30px]"></div>
        </div>
        <div className="w-full">
          <div className="flex w-full justify-between">
            <h2 className="font-[600]">Role Name</h2>
            <h2 className="font-[600]">Level 1</h2>
          </div>
          <div>
            <div className="relative h-1 bg-gray-300 rounded">
              <div
                className="absolute h-1 bg-blue-500 rounded"
                // style={{ width: `${levelPercentage}%` }}
                style={{ width: `40%` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full items-center">
        <div className="w-full">
          <h2 className="font-[600] text-[#636C7E] text-[12px]">Points</h2>
          <p className="font-[600]">100</p>
        </div>
        <hr className="border-l border-gray-300 h-1/3 mx-[16px]" />
        <div className="w-full">
          <h2 className="font-[600] text-[#636C7E] text-[12px]">Points</h2>
          <p className="font-[600]">100</p>
        </div>
        <hr className="border-l border-gray-300 h-1/3 mx-[16px]" />
        <div className="w-full">
          <h2 className="font-[600] text-[#636C7E] text-[12px]">Points</h2>
          <p className="font-[600]">100</p>
        </div>
      </div> */}
    </div>
  );
};

export default ParticipantCard;
