import React, { useEffect } from "react";
import { useState } from "react";
import ParticipantCard from "../../Components/HomeCards/ParticipantCard";
import DiscordCard from "../../Components/HomeCards/DiscordCard";
import BenefitsCard from "../../Components/HomeCards/BenefitsCard";
import { GET_EVENT_LIST_API } from "../../Constants/api";
import axios from "axios";
import { motion as m } from "framer-motion";

const Home = ({ homeInfo }) => {
  const [eventList, setEventList] = useState();
  const [eventLoading, setEventLoading] = useState(false);

  // const fetchEventList = async () => {
  //   setEventLoading(true);
  //   try {
  //     const res = await axios.get(GET_EVENT_LIST_API, {
  //       headers: {
  //         Authorization: `Bearer ${sessionStorage.getItem("bearer_token")}`,
  //       },
  //     });
  //     if (res.data) {
  //       setEventLoading(false);
  //       setEventList(res.data.data);
  //     }
  //   } catch (error) {
  //     setEventLoading(false);
  //     console.error("Failed to fetch event list:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchEventList();
  // }, []);

  return (
    <div className="flex flex-col gap-[48px] text-black">
      {/* Top part cards */}
      {homeInfo && (
        <m.div
          className="flex gap-[24px]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <ParticipantCard
            name={homeInfo.name}
            avatar={homeInfo.avatar}
            participated_year={homeInfo.created_at}
          />
          <DiscordCard
            discord_id={homeInfo.discord_id}
            discord_tag={homeInfo.discord_tag}
          />
          <BenefitsCard pastParticipant={homeInfo.past_participant} />
        </m.div>
      )}
      {/* Events section */}
      {/* {eventList && (
        <div className="flex flex-col gap-[24px]">
          <h1 className="font-[600] text-[25px]">Events</h1>
          <div className="flex flex-col w-full h-fit gap-[24px]">
            {eventList.map((event) => (
              <EventsCard eventDetails={event} />
            ))}
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Home;
